export const WORDS = [
    //'aave',
    'validador',
    'arbitraje',
    'oraculo',
    'roadmap',
    'trezor',
    //'bip',
    //'peg',
    //'gwei',
    'solow',
    'tightening',
    'tether',
    'address',
    //'ipfs',
    // 'shard',
    //'hash',
    //'mixer',
    'roi',
    'poap',
    'wrapped',
    'tokenized',
    'hash',
    'blockchain',
    'ledger',
    'cypherpunk',
    'harmony',
    'etherscan',
    'atomicswap',
    'framework',
    'wei',
    'alpha',
    'bluechip',
    'trading',
    'chainlink',
    'spread',
    'entropia',
    'ltv',
    'muun',
    'whisper',
    'criptodex',
    'lens',
    'earlybird',
    'frontrun',
    //'doc',
    //'tron',
    //'usdc',
    //'kyc',
    //'curve',
    //'ico',
    //'atom',
    //'eip',
    //'bear',
    //'ath',
    //'maker',
    //'dos',
    //'aml',
    //'nonce',
    //'asic',
    //'rsk',
    //'hype',
    //'shill',
    //'luna',
    //'dca',
    //'btfd',
    //'amm',
    //'apr',
    //'moon',
    //'ponzi',
    'otc',
    'ngmi',
    'gpu',
    'nakamoto',
    //'seed',
    'rig',
    'pos',
    //'phantom',
    //'apy',
    'asset',
    'fungible',
    //'bull',
    'cbdc',
    'cefi',
    'rollup',
    'sybil',
    'cex',
    'sat',
    'consenso',
    'indice',
    //this word should apper on monday 5 'riesgo',
    'onchain',
    'royalties',
    //'claim',
    //'dai',
    //'dao',
    //'faucet',
    'subnet',
    'casper',
    //'defi',
    //'dex',
    'dex',
    'crowdloan',
    'leverage',
    'ripio',
    'ziliqa',
    'reward',
    'rekt',
    //'finney',
    //'dns',
    //'dump',
    //'dyor',
    //'ether',
    //'fiat',
    //'contrato',
    'ethlatam',
    'alchemix',
    //'fomo',
    //'fork',
    //'fud',
    'dolphin',
    'buidl',
    //'gas',
    //'hodl',
    'aurora',
    //'lambo',
    'matic',
    'crosschain',
    //'inmutable',
    'tokenomics',
    //'mint',
    //'nft',
    'nodo',
    'altseason',
    'pool',
    'bridge',
    //'pos',
    //'pow',
    'pump',
    'scam',
    'presale',
    //'stake',
    'swap',
    'wagmam',
    'relaychain',
    'multisig',
    'grandpa',
    //'terra',
    //'token',
    //'trade',
    //'usdt',
    'vault',
    'wagmi',
    'whale',
    'metaverso',
    'defillama',
    //'wrap',
    //'zcash',
    //'evm',
    'zksync',
    'criptdle',
    'parachain',
    'defiant',
    'pancake',
    'mainnet',
    'shilling',
    'vesting',
    //'doge',
    //'sushi',
    //'merge',
    //'clave',
    //'block',
    //'chain',
    //'quema',
]
